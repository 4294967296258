import { AccountTagListView } from ".";

const rootPath: string = "/tags";

export const routes = [
    {
        path: `${rootPath}`,
        name: "taglist",
        component: AccountTagListView,
        meta: { layout: "basic" },
    },
];
