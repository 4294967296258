











































































































import { Component, Vue, Prop } from "vue-property-decorator";

import { CreateTagModel, TagModel, TagService } from "@/core/services";

import AppVue from "@/AppVue.vue";

@Component({
  components: {},
})
export default class TagListCpt extends AppVue {
  loading: boolean = false;
  tags: TagModel[] = [];
  newTag: CreateTagModel = new CreateTagModel();
  editTag: TagModel = new TagModel();
  get message() {
    return this.tagExists() ? "Tag exists" : "";
  }
  async addNewTag() {
    this.loading = true;
    const res = await TagService.createTag(this.newTag);
    this.tags.push(res);
    this.newTag = new CreateTagModel();
    this.loading = false;
  }
  async renameTag() {
    this.loading = true;

    const res = await TagService.renameTag(this.editTag);
    const oldTag = this.tags.findIndex((x) => x.userTagId === this.editTag.userTagId);
    this.tags[oldTag] = res;
    this.editTag = new TagModel();
    this.loading = false;
  }
  async deleteTag(toDelete: TagModel) {
    this.loading = true;
    const index = this.tags.indexOf(toDelete, 0);
    await TagService.deleteTag(toDelete.userTagId);
    if (index > -1) {
      this.tags.splice(index, 1);
    }
    this.loading = false;
  }

  tagExists() {
    return this.tags.findIndex((x) => x.name.toLowerCase() === this.newTag.name.toLowerCase()) > -1;
  }

  cancelRename() {
    this.editTag = new TagModel();
  }
  async created() {}
  startEdit(toEdit: TagModel) {
    this.editTag = { ...toEdit };
  }

  async mounted() {
    this.loading = true;

    this.tags = await TagService.getAllVisibleTags();
    this.loading = false;
  }
}
