
















import { Component, Vue } from "vue-property-decorator";
import { SubscriptionService, SubscriptionModel } from "@/core/services";

import {} from "@/core/components";
import {} from "@/core/models";
import { SubscriptionItemCpt } from "./components";
import AppVue from "@/AppVue.vue";

@Component({
  components: { SubscriptionItemCpt },
})
export default class SubscriptionView extends AppVue {
  model: SubscriptionModel = new SubscriptionModel();
  subscriptionId = 0;
  async created() {
    this.subscriptionId = parseInt(this.$route.params.subscriptionId, 10);
    this.model = (await SubscriptionService.getAll()).filter((x) => x.subscriptionID)[0];
  }

  async mounted() {}
}
