











import { Component, Vue } from "vue-property-decorator";
import {} from "@/core/services";
import {} from "@/core/components";
import {} from "@/core/models";

import AppVue from "@/AppVue.vue";
import TagListCpt from "./components/TagListCpt.vue";

@Component({
  components: {
    TagListCpt: () => Promise.resolve(TagListCpt),
  },
})
export default class AccountTagListView extends AppVue {
  async created() {}

  async mounted() {}
}
