














































import { Component, Vue, Prop, Watch } from "vue-property-decorator";

import { TagModel, TagService } from "@/core/services";
import {} from "@/core/components";

import AppVue from "@/AppVue.vue";

export class Type {}

@Component({
  components: {},
})
export default class ProjectTagListCpt extends AppVue {
  @Prop({ default: 0 }) projectId: number;
  loading: boolean = false;
  tags: TagModel[] = [];
  allTags: TagModel[] = [];
  inputVisible = false;
  inputValue = "";
  $refs: {
    saveTagInput: any;
  };
  get isLoading() {
    return this.loading;
  }

  get availableTags() {
    const ee = this.allTags.filter((x) => this.tags.findIndex((t) => t.userTagId === x.userTagId) === -1);
    ee.push({ userTagId: -1, name: "Create new tag" } as TagModel);
    return ee;
  }

  @Watch("projectId")
  async reloadTagsOnProjectChange() {
    await this.init();
  }
  showInput() {
    this.inputVisible = true;
    this.$nextTick(() => {
      this.$refs.saveTagInput.$refs.input.focus();
    });
  }
  async created() {}

  async mounted() {
    await this.init();
  }
  // never used
  async addNewTag() {
    this.loading = true;
    try {
      const tagName = this.inputValue;
      if (tagName !== "") {
        this.tags = await TagService.addNewTagToProject(this.projectId, { name: tagName });
      }
    } catch {}
    this.inputVisible = false;
    this.inputValue = "";
    this.loading = false;
  }
  async addTag(tag: TagModel) {
    if (tag.userTagId === -1) {
      this.showInput();
      return;
    }
    this.loading = true;
    this.tags = await TagService.addTagToProject(this.projectId, tag.userTagId);
    this.loading = false;
  }
  async removeTag(tag: TagModel) {
    this.loading = true;
    this.tags = await TagService.removeTagFromProject(this.projectId, tag.userTagId);
    this.loading = false;
  }
  async init() {
    this.loading = true;

    this.tags = await TagService.getTagsForProject(this.projectId);
    this.allTags = await TagService.getAllVisibleTags();
    this.loading = false;
  }
}
