import { SubscriptionsView, SubscriptionView } from ".";
import { MapView } from "../map";

const rootPath: string = "/subscriptions";

export const routes = [
  {
    path: `${rootPath}`,
    name: "subscriptions",
    component: SubscriptionsView,
    meta: { layout: "basic" },
  },
  {
    path: `${rootPath}/:subscriptionId`,
    name: "subscription",
    component: SubscriptionView,
    meta: { layout: "basic" },
  },
];
