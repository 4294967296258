



































import { Component, Vue } from "vue-property-decorator";
import { AlertService, AlertModel } from "@/core/services";
import {} from "@/core/components";
import {} from "@/core/models";
import { SubscriptionService, SubscriptionModel } from "@/core/services";
import { SubscriptionItemCpt } from "./components";

import AppVue from "@/AppVue.vue";
import moment from "moment";

@Component({
  components: {},
})
export default class SubscriptionsView extends AppVue {
  model: SubscriptionModel[] = [];
  async created() {
    const dateFormat = "MMM Do YY";
    this.model = (await SubscriptionService.getAll()).map((x: SubscriptionModel) => ({
      ...x,
      endDate: moment.utc(x.endDate).isValid() ? moment.utc(x.endDate).local().format(dateFormat) : "",
      startDate: moment.utc(x.startDate).isValid() ? moment.utc(x.startDate).local().format(dateFormat) : "",
      invoiceDate: moment.utc(x.invoiceDate).isValid() ? moment.utc(x.invoiceDate).local().format(dateFormat) : "",
    }));
  }

  async mounted() {}
}
