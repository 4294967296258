






















import { Component, Vue, Prop } from "vue-property-decorator";
import { SectorIconCpt } from "@/core/components";
import { SubscriptionService, SubscriptionModel } from "@/core/services";
import { roleFlag } from "@/core/constants";

import AppVue from "@/AppVue.vue";

@Component({
  components: {},
})
export default class SubscriptionItemCpt extends AppVue {
  @Prop({}) model: SubscriptionModel;
  async created() {}

  async mounted() {}
}
